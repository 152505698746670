import { DashboardLayout, PageContainer } from '@toolpad/core';
import React from 'react';
import { Outlet } from 'react-router';


export function Layout() {
  return (
    <DashboardLayout disableCollapsibleSidebar >
      <Outlet />
    </DashboardLayout>
  )
}