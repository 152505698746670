import { Box, Button, FormControl, IconButton, InputLabel, OutlinedInput, Paper, styled, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../Context/AuthContext";
import { useNotifications } from "@toolpad/core";
import { ItemType } from "../Secretcodes";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useDropzone } from "react-dropzone";
import CancelIcon from '@mui/icons-material/Cancel';
import MediaUpload from "../../Components/MediaUpload";

const NumberInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

export default function EditItem() {
  const { uid } = useParams();
  const { token } = useAuth();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [item, setItem] = useState<ItemType>({} as ItemType);
  const [ profilePic, setProfilePic ] = useState<string>("")
  const [ numCodes, setNumCodes ] = useState<number>(0)
  const [ itemName, setItemName ] = useState<string>('')
  const [ itemPrice, setItemPrice ] = useState<number>(0)
  const [ itemSummary, setItemSummary ] = useState<string>('')
  // const [ resFiles, setResFiles ] = useState<string[]>([])
  const [ resLinks, setResLinks ] = useState<string[]>([])

  const notification = useNotifications();
  const navigate = useNavigate();

  function clearPic() {
    setProfilePic('')    
  }

  function handleChange(e:ChangeEvent<HTMLInputElement>, setActions:CallableFunction) {
    setActions(e.target.value)
  }

  async function handleSubmit() {
    if( numCodes > 100 ) {
      notification.show("保持理智，最多100个号", {
        severity: 'error',
        autoHideDuration: 3000
      })
      return
    };
    if( itemName.length < 5) {
      notification.show("需要提供产品名称，最少几个字哈",{
        severity: 'info',
        autoHideDuration: 3000
      })
      return
    }
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+token
        },
        body: JSON.stringify({
          name: itemName,
          pic: profilePic,
          code_number: numCodes,
          price: itemPrice,
          summary: itemSummary,
          resources: resLinks,
          uid: item?.uid
        })
      };
      const url = !isEdit ? '/api/createItem' : '/api/updateItem'
      const res = await fetch(url, options)
      if(res.status === 200) {
        notification.show('成功增加产品', {
          severity: 'success',
          autoHideDuration: 3000
        })
        navigate(-1)
      } 
      if( res.status === 401 || res.status === 403) {
        const detail = await res.json()
        notification.show(detail.detail.message, {
          severity: 'error',
          autoHideDuration: 3000
        })
        navigate('/login')
      }
      if (res.status >= 400) {
        const detail = await res.json()
        notification.show('Error '+res.status+' '+detail.detail.message, {
          severity: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      console.log(err)
    }
  }


  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    // Do something with the files
    if (acceptedFiles.length > 0 && fileRejections.length <= 0) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        setProfilePic(e.target?.result as string);
      };
      console.log(acceptedFiles[0])
      fileReader.readAsDataURL(acceptedFiles[0]);
    } else {
      notification.show("选择的图片资源只能是bmp,png或jpg", {
        severity: 'info',
        autoHideDuration: 3000,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".PNG"],
      "image/bmp": [".bmp", ".BMP"],
      "image/jpeg": [".jpg", ".JPG"],
    },
    noClick: profilePic ? true : false,
  });
 
  // async function getURLResources(name:string) {
  //   try {
  //     const res = await fetch('/upload/media/'+name);
  //     const dt = await res.arrayBuffer()
  //     const base64String = btoa( new Uint8Array(dt).reduce((data,byte) => data + String.fromCharCode(byte), ''))
      
  //     setResFiles(prev => [...prev, 'data:image/png;base64,'+base64String])
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  async function getItem() {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          item_uid: uid,
        }),
      };
      const res = await fetch("/api/item/" + uid, options);
      if (res.status === 200) {
        const data = await res.json();
        setItem(data);
        if (data.resources) {
          setResLinks(JSON.parse(data.resources))
        }
        setIsReady(true);
        setItemName(data.name)
        setItemPrice(data.price)
        setProfilePic(data.pic)
        setItemSummary(data.summary)
      }
      if (res.status === 403) {
        navigate("/login");
      }
      if (res.status === 400) {
        const { detail } = await res.json();
        notification.show(detail.message, {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function onPicAvailable(link:string, index:number) {
    // setResFiles(prev => [ ...prev, pic ])
    console.log(index, resLinks.length)
    if( index === resLinks.length) {
      setResLinks(prev => [ ...prev, link ])
    } else {
      setResLinks( prev => [ ...prev.slice(0, index), link, ...prev.slice(index+1)])
    }
    
  }

  function onPicDelete(index:number) {
    // const newres = resFiles.filter((_,i) => i !== index)
    // setResFiles(newres)
    setResLinks( prev => prev.filter((_,i) => i !== index) )
  }

  useEffect(() => {
    if (uid) {
      setIsEdit(true);
      setIsReady(false);
      getItem();
    } else {
      setIsEdit(false);
      setIsReady(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position:'relative',
        p: 2,
        gap: 1,
        minHeight: 1,
        width:1
      }}
    >
      <Typography variant="h4">
        {!!!isEdit ? "新增" : "编辑"}表盘产品
      </Typography>
      <Paper
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          p: 1,
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Box sx={{ position:'relative'}} {...getRootProps()}>
            <input {...getInputProps()} />
            <Box
              sx={{ display: "flex", flexDirection: 'row', minWidth: 200, height: 200, borderStyle: "dashed", borderColor: "GrayText",
                borderRadius: 1, justifyContent:'center', alignItems: 'center',
              }}  
            > 
              { !!!profilePic ? (
                  !isDragActive ? (
                    <AddPhotoAlternateOutlinedIcon sx={{ color:'GrayText', width: 60, height: 60 }} />
                  ) : (
                    <ArrowDropDownCircleOutlinedIcon sx={{ color:'GrayText', width: 60, height: 60 }} />
                  )
                ):(
                  <Box sx={{ display:'flex', flexDirection:'column', height:1, justifyContent:'center'}}>
                    <img src={profilePic} style={{ width:200, maxHeight:'100%', objectFit:'cover' }} alt="pic" />
                  </Box>
                    )  
              }
            </Box>
            { !!profilePic && (
              <Box sx={{position:'absolute', top:-20, right:-20}}>
                <IconButton onClick={clearPic}>
                  <CancelIcon sx={{color:'red', width:24, height:24}} />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            component="form"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {!!isReady && (
              <>
                <FormControl sx={{ width: '50ch'}}>
                  <InputLabel htmlFor='textfield_item_n'>名称</InputLabel>
                  <OutlinedInput
                    aria-label="textfield_item_n"
                    label="名称"
                    value={itemName}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e, setItemName)}
                  />
                </FormControl>
                <FormControl sx={{ width: '50ch'}}>
                  <InputLabel htmlFor='textfield_price'>价格</InputLabel>
                  <NumberInput
                    aria-label="textfield_price"
                    label="价格"
                    type="number"
                    value={itemPrice}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e, setItemPrice)}
                  />
                </FormControl>
                {!!isEdit ? (
                    <TextField
                      variant="standard"
                      label="表盘代码"
                      defaultValue={item.uid}
                      disabled
                    />
                  ):(
                    <FormControl sx={{ width: '50ch'}}>
                      <InputLabel htmlFor='textfield_num'>新增密卡数量</InputLabel>
                      <NumberInput
                        aria-label="textfield_num"
                        label="新增密卡数量"
                        type="number"
                        value={numCodes}
                        onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e, setNumCodes)}
                      />
                    </FormControl>
                  )
                }
              </>
            )}
            <Box sx={{ display: "flex", width: 1 }}>
              <TextField
                variant="outlined"
                label="描述"
                multiline
                rows={4}
                sx={{ width: 1 }}
                value={itemSummary}
                onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e, setItemSummary)}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection:'column', width: 1 }}>
              <Typography sx={{fontSize:12, textDecorationLine:'underline', ml:2, mb:1}}>资源</Typography>
              <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'revert', flexWrap:'wrap', width: 1, gap:2 }}>
                {
                    resLinks.map((rfile, idx) => {
                      console.log(rfile)
                      return (
                        <MediaUpload key={idx} onPicAvailable={onPicAvailable} onPicDelete={onPicDelete} index={idx} linkPic={rfile} />
                      )
                    })
                }
                <MediaUpload onPicAvailable={onPicAvailable} onPicDelete={onPicDelete} index={resLinks.length} linkPic={''}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box sx={{position:'fixed', bottom:16, width:'calc(100% - 320px - 32px)'}}>
        <Box sx={{display:'flex', flexDirection:'row', gap:2, justifyContent:'end'}}>
          <Button variant="contained" onClick={handleSubmit}>确定{!!!isEdit?'增加':'修改'}</Button>
          <Button variant="outlined" onClick={()=>navigate(-1)}>取消</Button>
        </Box>
      </Box>
    </Box>
  )


  return (
    <>
      { isReady ? content : null }
    </>
  );
}
