import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useNotifications } from "@toolpad/core";
import { useDropzone } from "react-dropzone";
import CancelIcon from '@mui/icons-material/Cancel';
import md5 from 'md5'
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router";

type MediaUploadProps = {
  onPicAvailable: (link:string, index:number) => void
  onPicDelete: (idx:number) => void
  index: number
  linkPic?: string
}

export default function MediaUpload( {onPicAvailable, onPicDelete, index, linkPic }:MediaUploadProps) {
  // const [ picFile, setPicFile ] = useState<string>('')
  const [ filelink, setFilelink ] = useState<string>('')

  const notification = useNotifications()
  const navigate = useNavigate()
  const { token } = useAuth()

  function clearPic() {
    onPicDelete(index)
  }

  function upload(upfile:File) {
    let base64pic = ''
    let fileReader = new FileReader();
    
    fileReader.onload = async (e) => {
      base64pic = e.target?.result as string
      const hash = md5(base64pic)
      const formData = new FormData(); 
      formData.append('file', upfile)
      formData.append('name', hash)
      try {
        const options = {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + token,
          },
          body: formData
        }
        const res = await fetch('/api/upload_media', options)
        if( res.status === 200 ){
          const data = await res.json()
          onPicAvailable(data.link, index)
        }
        if( res.status === 401 || res.status === 403) {
          navigate('/login')
        }
        if( res.status === 400 ) {
          const detail = await res.json()
          notification.show(detail.detail.message, {
            severity: 'error',
            autoHideDuration: 3000
          })
        }
      } catch (err) {
        console.log(err)
      }
    };
    fileReader.readAsDataURL(upfile);
  }

  const onDrop = (acceptedFiles: File[], fileRejections: any) => {
    if (acceptedFiles.length > 0 && fileRejections.length <= 0) {
      console.log(acceptedFiles[0])
      upload(acceptedFiles[0])
    } else {
      notification.show("选择的图片资源只能是bmp,png或jpg", {
        severity: 'info',
        autoHideDuration: 3000,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".PNG"],
      "image/bmp": [".bmp", ".BMP"],
      "image/jpeg": [".jpg", ".JPG"],
      "video/mp4": [".mp4", ".MP4"],
    },
    noClick: filelink ? true : false,
  });

  const DragActiveComp = (
    isDragActive ? (
      <Box component='div' sx={{width:1, height:1, display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center'}}>
        <ArrowDropDownCircleOutlinedIcon sx={{ color:'GrayText', width: 60, height: 60 }} />
      </Box>
    ) : (        
      <Box component='div' sx={{width:1, height:1, display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center'}}>
        <AddPhotoAlternateOutlinedIcon sx={{ color:'GrayText', width: 60, height: 60 }} />
      </Box>
    )  
  )

  useEffect(() => {
    if (linkPic) {
      const filearr = linkPic.split('.')
      if (filearr[filearr.length-1] === 'MP4' || filearr[filearr.length-1] === 'mp4') {
        setFilelink('mp4.png')
      } else {
        setFilelink(linkPic)
      }
    } 
  }, [linkPic])

  return (
    <Box component='section' sx={{position:'relative'}}>
      <Box
        component='div'
        sx={{
          display: "flex",
          flexDirection: "row",
          width: 200,
          height: 200,
          borderStyle: "dashed",
          borderRadius: 1,
          borderColor: 'GrayText',
        }}
        { ...getRootProps({ style:{ width:200, height:200}}) }
      >
        <input {...getInputProps()} />
        { !!!filelink ? DragActiveComp : 
          (
            <Box sx={{ display:'flex', flexDirection:'column', height:1, justifyContent:'center'}}>
              <img src={'/upload/media/'+filelink} style={{ width:200, maxHeight:'100%', objectFit:'cover' }} alt="pic" />
            </Box>
          )
        }
      </Box>
      { filelink && (
        <Box sx={{position:'absolute', top:-20, right:-20}}>
          <IconButton onClick={clearPic}>
          <CancelIcon sx={{color:'red', width:24, height:24}} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
