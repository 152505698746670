import React, { createContext, ReactNode, useContext, useEffect } from 'react';

type ItemContextType = {
  item_uid: string
  item_name: string
}

const ItemContext = createContext<ItemContextType>({} as ItemContextType)

type Props = {
  children : ReactNode,
  item_uid : string,
  item_name : string
}

const ItemProvider = ( {children, item_name, item_uid }:Props ) => {

  return (
    <ItemContext.Provider value={{ item_name, item_uid}}>
      { children }
    </ItemContext.Provider>
  )
};

const useItem = () => {
  const context = useContext(ItemContext);
  if (!!!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { ItemProvider, useItem}