import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Navigate } from "react-router";

export default function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ isAuth, setIsAuth ] = useState<boolean>(false);


  function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    callable: CallableFunction
  ) {
    callable(e.target.value);
  }

  const loginUser = async (username:string, password:string) => {
    try {
      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({ username, password})
      }) 
      if (res.status === 200) {
        const data = await res.json()
        localStorage.setItem('token', data.access_token)
        setIsAuth(true)
      }
    } catch (err) {
      console.log(err)
    }
  };

  const loginForm = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: "center",
      }}
      component={'form'}
    >
      
      <Paper
        sx={{
          p: 4,
          minWidth: 1 / 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 800, pb: 2, textAlign: "center" }}
        >
          OSZERO表盘管理系统
        </Typography>
        <TextField
          variant="outlined"
          label="用户名"
          value={username}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, setUsername)
          }
        />
        <TextField
          variant="outlined"
          type="password"
          label="密码"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, setPassword)
          }
        />
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 4 }}
          onClick={(e) => loginUser(username, password)}
        >
          登入
        </Button>
      </Paper>
      
    </Box>
  )

  return (
    <>
    { !!!isAuth && loginForm}
    { !!isAuth && <Navigate to={'/dashboard'} replace/> }
    </>
  );
}
