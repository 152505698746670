import { Box, Button, Grid2, Typography } from '@mui/material'
import React from 'react'
import FolderOffIcon from "@mui/icons-material/FolderOff";


export type ProdTypes = {
  uid: string,
  ref_item: string,
}

type ProdTableProps = {
  list: ProdTypes[],
  onDisclose? : (item_uid:string, prod_uid:string) => void,
  onVerify? : (item_uid:string, prod_uid:string) => void,
}

export default function ProdTable( props: ProdTableProps) {
  const { list, onDisclose, onVerify } = props
  return (
    <Grid2 container spacing={2}>
    { list.length > 0 ?
      list.map( (prod,idx) => (
      <Grid2 key={idx} size={'auto'} sx={{display:'flex', flexDirection:'row', gap:1, justifyContent:'space-around'}}>
        <Typography variant='body1' sx={{fontFamily:'monospace'}}>{prod.uid}</Typography>
        <Button size='small' color='secondary' variant='outlined' sx={{py:0}} onClick={async ()=>await navigator.clipboard.writeText(prod.uid)}>复制</Button> 
        { onDisclose && <Button size='small' variant='outlined' sx={{py:0}} onClick={()=>onDisclose(prod.ref_item, prod.uid)}>发出</Button>}
        { onVerify && <Button size='small' color='error' variant='outlined' sx={{py:0}} onClick={()=>onVerify(prod.ref_item, prod.uid)}>手验</Button>}
      </Grid2>
      )) : (
        <Grid2 key={list.length} size={12} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
          <Box sx={{display:'inline-flex', flexDirection:'row', color:'GrayText', gap:2}}>
            <FolderOffIcon />
            <Typography variant="inherit">未有数据</Typography>
          </Box>
        </Grid2>
      )
    }
    </Grid2>
  )
}