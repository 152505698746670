import { Box, Typography } from "@mui/material";

export default function Dashboard() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        gap: 1,
        minHeight: 1,
      }}
    >
      <Typography variant="h4">Dashboard</Typography>
    </Box>
  );
}
