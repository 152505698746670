import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";

export default function Logout() {
  const navigate = useNavigate()

  useEffect(()=>{
    localStorage.removeItem('token')
    navigate('/login')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CircularProgress />
  )
}