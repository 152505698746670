import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type UserContextType = {
  isAuthenticated: boolean,
  token: string | null,
  // loginUser: (username:string, password:string) => void,
  // logout: () => void,
}

const AuthContext = createContext<UserContextType>({} as UserContextType)

type Props = {
  children : ReactNode
}

const AuthProvider = ( {children}:Props ) => {
  const [token, setToken] = useState<string|null>(null)
  const [isReady, setIsReady] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const navigate = useNavigate()


  async function verifyToken(tk:string) {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+tk
        }
      };
      const res = await fetch("/api/reAuth", options)
      if (res.status === 200) {
        setToken(tk)
        setIsAuthenticated(true)
        setIsReady(true)
      } 
      if (res.status === 403) {
        setToken(null)
        setIsAuthenticated(false)
        localStorage.removeItem("token")
        navigate('/login')
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(()=>{
    const tk = localStorage.getItem('token');
    if (!tk) {
      navigate('/login')
    }
    if (tk) {
      verifyToken(tk)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // const loginUser = async (username:string, password:string) => {
  //   await fetch("/api/login", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded"
  //     },
  //     body: new URLSearchParams({ username, password})
  //   }).then( async (res) => {
  //     if (res.status === 200) {
  //       const data = await res.json()
  //       localStorage.setItem('token', data.access_token)
  //       setToken(data.access_token)
  //       setIsAuthenticated(true)
  //     } else if( res.status === 400) {
  //       const data = await res.json()
  //       await dialogs.alert(data.detail.message)
  //     }
  //   }).catch( async (e) => await dialogs.alert(e))
  // };

  // const logout = () => {
  //   localStorage.removeItem("token")
  //   setToken('')
  //   setIsAuthenticated(false)
  // }

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, }}>
      { isReady ? children : null}
    </AuthContext.Provider>
  )
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!!!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth}