import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, styled, Typography } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { useItem } from '../../Context/ItemContext';
import { useAuth } from '../../Context/AuthContext';
import { useNotifications } from '@toolpad/core';
import { useNavigate } from 'react-router';


type AddProdProps = {
  open: boolean,
  onClose: () => void
}

const NumberInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));


export function Addproduct( props: AddProdProps) {
  const { open, onClose } = props
  const { item_name, item_uid } = useItem()
  const { token } = useAuth()

  const [ increseby, setIncreaseby ] = useState<number>(0)

  const notification = useNotifications()
  const navigate = useNavigate()

  async function createProducts() {
    try {
      const options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          item_uid : item_uid,
          num : increseby
        })
      }
      const res = await fetch('/api/create_pass_card', options)
      if( res.status === 200) {
        notification.show('成功增加'+increseby+'密卡', {
          severity: 'success',
          autoHideDuration: 3000
        })
      }
      if( res.status === 403 ) {
        navigate('/login')
      }
      if( res.status === 400 ) {
        const detail = await res.json()
        notification.show(detail.detail.message, {
          severity: 'error',
          autoHideDuration: 3000
        })
      } 
    } catch(err) {
      console.log(err)
    }
  }

  function handleClose() {
    onClose()
  }

  function handleInput(e:ChangeEvent<HTMLInputElement>, setStateCall:CallableFunction) {
    setStateCall(e.target.value)
  }

  function handleCreate() {
    if (increseby > 100) {
      notification.show('保持理智，最多100个号', {
        severity: 'info',
        autoHideDuration: 3000
      })
      return
    }
    if (increseby < 1) {
      notification.show('保持理智，最少也要1个', {
        severity: 'info',
        autoHideDuration: 3000
      })
      return
    }

    createProducts()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle align='center'>增加密卡</DialogTitle>
      <DialogContent>
        <Box sx={{display:'flex', flexDirection:'column', pt:2, gap:2 }}>
          <Typography variant='subtitle1'>产品名称： {item_name}</Typography>
          <FormControl>
            <InputLabel htmlFor="code_num" size="small">
              密卡数量
            </InputLabel>
            <NumberInput
              id="code_num"
              size="small"
              label="验证码数量"
              type="number"
              value={increseby}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {handleInput(e, setIncreaseby)}}
            />
          </FormControl>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCreate}>确定</Button>
        <Button variant="outlined" onClick={handleClose}>取消</Button>
      </DialogActions>
    </Dialog>
  )
}